import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearStorage } from "@store/browser-storage/storage.service";
import { type RootState } from "@src/store/store";

interface StateType {
  role: "user" | "association" | "admin" | "";
  isAuth: boolean;
  userEmail: string;
  profile: null | {
    email: string;
    family_name: string;
    given_name: string;
    hd: string;
    id: string;
    locale: string;
    name: string;
    picture: string;
    verified_email: boolean;
  };
}

const initialState = {
  role: "",
  isAuth: false,
  userEmail: "",
  profile: null,
} as StateType;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<StateType["role"]>) => {
      state.role = action.payload;
    },
    setAuth: (state, action: PayloadAction<StateType["isAuth"]>) => {
      state.isAuth = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<StateType["userEmail"]>) => {
      state.userEmail = action.payload;
    },
    setProfile: (state, action: PayloadAction<StateType["profile"]>) => {
      state.isAuth = true;
      state.profile = action.payload;
    },
    handleLogout: (state) => {
      return initialState;
    },
    resetAuth: (state) => {
      state.role = "";
      state.isAuth = false;
      state.userEmail = "";
      state.profile = null;
    },
  },
});

export const {
  setRole,
  setAuth,
  resetAuth,
  setUserEmail,
  setProfile,
  handleLogout,
} = userSlice.actions;
export default userSlice.reducer;

export const selectUserEmail = (state: RootState) => state.user.userEmail;
export const selectIsAuth = (state: RootState) => state.user.isAuth;
export const selectProfile = (state: RootState) => state.user.profile;
export const selectRole = (state: RootState) => state.user.role;

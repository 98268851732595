import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Providers } from "@components/Providers";
import { App } from "@src/App";
import {
  associationPrivateRoutes,
  authRoutes,
  publicRoutes,
  userPrivateRoutes,
} from "@src/configs/routes";
import { Amplify } from "aws-amplify";
import config from "@configs/aws-config";
import { NotFoundPage } from "@src/pages/NotFound";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

import "react-loading-skeleton/dist/skeleton.css";

// ** Core styles
import "./assets/fonts/basics/basicfonts.css";
import "./assets/scss/core.scss";
import "./assets/scss/style.scss";
import "@styles/react/libs/flatpickr/flatpickr.scss";
import "react-datepicker/dist/react-datepicker.css";

// ** Service Worker
Amplify.configure(config);
const container: any = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Providers>
        <App />
      </Providers>
    ),
    children: [
      authRoutes,
      userPrivateRoutes,
      associationPrivateRoutes,
      publicRoutes,
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store/store";
import { paths } from "@configs/routes/paths";

export type CurrentBreadCrumb = {
  title: string;
  link: string;
  header: string;
  prevLinks?: { title: string; link: string }[];
};
interface BreadCrumbState {
  breadCrumbs: CurrentBreadCrumb[];
  currentBreadCrumb: CurrentBreadCrumb | null;
  effectInAppTrigger: boolean;
}

const initialBreadCrumb: CurrentBreadCrumb = {
  title: "Dashboard",
  link: paths.userDashboard,
  header: "MMCEPI Dashboard",
};
const initialState: BreadCrumbState = {
  breadCrumbs: [initialBreadCrumb],
  currentBreadCrumb: initialBreadCrumb,
  effectInAppTrigger: false,
};

export const breadCrumbSlice = createSlice({
  name: "breadCrumb",
  initialState,
  reducers: {
    handleBreadCrumbs: (
      state,
      action: PayloadAction<BreadCrumbState["breadCrumbs"]>
    ) => {
      state.breadCrumbs = action.payload;
    },

    setCurrentBreadCrumb: (
      state,
      action: PayloadAction<{
        selectedBreadCrumbs: CurrentBreadCrumb[];
        replace?: boolean;
      }>
    ) => {
      const { replace, selectedBreadCrumbs } = action.payload;

      // get last item in list. this will be the current breadcrumb
      const breadCrumb = selectedBreadCrumbs.at(-1);

      if (breadCrumb) {
        state.currentBreadCrumb = breadCrumb;

        // if replace is true then selectedBreadCrumbs will be assigned to breadCrumbs
        // otherwise it will be  concat to breadCrumbs
        state.breadCrumbs = replace
          ? selectedBreadCrumbs
          : state.breadCrumbs.concat(selectedBreadCrumbs);
      }
    },
    handleClickBreadCrumb: (state, action: PayloadAction<number>) => {
      // if click on breadCrumb, breadCrumbsList slice until index of selectedBreadCrumb
      state.breadCrumbs = state.breadCrumbs.slice(0, action.payload + 1);
      // and item with index will be selectedBreadCrumb
      state.currentBreadCrumb =
        state.breadCrumbs.at(action.payload) ?? initialBreadCrumb;
    },
    handleTriggerEffectInApp: (state) => {
      state.effectInAppTrigger = !state.effectInAppTrigger;
    },
  },
});

export const {
  handleBreadCrumbs,
  setCurrentBreadCrumb,
  handleClickBreadCrumb,
  handleTriggerEffectInApp,
} = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;

export const selectBreadCrumb = (state: RootState) =>
  state.breadCrumb.currentBreadCrumb;
export const selectBreadCrumbs = (state: RootState) =>
  state.breadCrumb.breadCrumbs;
export const selectTriggerEffectInApp = (state: RootState) =>
  state.breadCrumb.effectInAppTrigger;

import { ReactNode } from "react";

type ChildrenProps<P extends any = {}> = P & {
  children: ReactNode | ReactNode[];
};
/**
 │ * for conditional rendering
 │ * @param isTrue if true children will be rendered
 │ * @param children
 │ * @constructor
 │ */

export const RenderIf = ({
  isTrue,
  children,
}: ChildrenProps<{ isTrue: boolean }>) => (isTrue ? <>{children}</> : null);

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { paths } from "@configs/routes";

export const App = () => {
  const pathName = useLocation().pathname;

  if (pathName === "/") return <Navigate to={paths.search} />;

  return (
    <div className="App bg-white10" id="appContainer">
      <Outlet />
    </div>
  );
};

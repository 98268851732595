import React, { CSSProperties, ReactNode } from "react";
import { getFontFamily, getFontSize } from "@src/utils/Utils";
import { EFonts } from "@src/core/enum/fonts.enum";

interface ITypographyProp {
  tag?: React.ElementType;
  block?: boolean;
  size?:
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 18
    | 20
    | 22
    | 24
    | 36
    | 37
    | 125;
  className?: string;
  children?: ReactNode;
  font?: EFonts;
  style?: CSSProperties;
  onClick?: any;
  id?: string;
  refItem?: React.LegacyRef<any>;
  noFont?: boolean;
}

export const Typography = ({
  tag,
  block,
  size,
  className,
  children,
  font,
  style,
  onClick,
  id,
  refItem,
  noFont = true,
}: ITypographyProp) => {
  const Tag = tag || "span";

  const fontSize = getFontSize(size);

  const fontFamily = getFontFamily(font);

  return (
    <Tag
      className={`${block ? "d-block" : ""} ${fontFamily} ${
        !noFont ? "" : fontSize
      } ${className}`}
      style={style}
      onClick={onClick}
      id={id}
      ref={refItem}
    >
      {children}
    </Tag>
  );
};

import { useErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import { BlueButton } from "@components/common/BlueButton";
import { RenderIf } from "@components/common/RenderIf";
import errorLogo from "@assets/images/error/errorBoundary.jpeg";

const ImgWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  max-height: 500px;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
`;
const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const Pre = styled.pre`
  color: red;
`;

export const ErrorBoundaryFallBack = ({ error }: { error: Error }) => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div
      role="alert"
      className="d-flex flex-column justify-content-center align-items-center gap-1"
    >
      <p>Something went wrong:</p>
      <RenderIf isTrue={process.env.NODE_ENV === "development"}>
        <Pre>{error.message}</Pre>
        <p>{error.stack}</p>
      </RenderIf>
      <ImgWrapper className="shadow">
        <Img src={errorLogo} alt="error" />
      </ImgWrapper>
      <BlueButton onClick={resetBoundary} className="w-fit">
        Try again
      </BlueButton>
    </div>
  );
};

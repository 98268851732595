import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { type RootState } from "@src/store/store";

interface StateType {
  joinRequestId: string | number | undefined;
}

const initialState = {
  joinRequestId: undefined,
} as StateType;
const secureAppSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setJoinRequestId: (
      state,
      action: PayloadAction<StateType["joinRequestId"]>
    ) => {
      state.joinRequestId = action.payload;
    },
  },
});

export const { setJoinRequestId } = secureAppSlice.actions;
export default secureAppSlice.reducer;

export const selectJoinRequestId = (state: RootState) =>
  state.secureAppSlice.joinRequestId;

import React from "react";
import { Button, type ButtonProps } from "reactstrap";
import classNames from "classnames";

interface IBlueButtonProps extends ButtonProps {
  children?: React.ReactNode;
}
export const BlueButton = ({
  children,
  className,
  ...buttonProps
}: IBlueButtonProps) => {
  return (
    <Button
      type="button"
      block
      color=""
      className={classNames("blueButton", className)}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ESidebar } from "@src/core/enum/sidebar.enum";
import { useSelector } from "react-redux";
import { RootState } from "./store";

const initialState = {
  sidebarLayout: ESidebar.ProfileInfo,
  prevSideBar: ESidebar.ProfileInfo,
  isOpen: false,
};
type StateType = typeof initialState;

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    handleSidebarLayout: (
      state,
      action: PayloadAction<StateType["sidebarLayout"]>
    ) => {
      if (state.prevSideBar !== state.sidebarLayout)
        state.prevSideBar = state.sidebarLayout;
      else state.prevSideBar = ESidebar.ProfileInfo;
      state.sidebarLayout = action.payload;
      if (window.innerWidth < 1200 && !state.isOpen) state.isOpen = true;
    },
    handleOpenSideBar: (
      state,
      action: PayloadAction<StateType["isOpen"] | number>
    ) => {
      const { payload } = action;
      if (typeof payload === "number") {
        if (payload === 3) state.isOpen = false; // forse close
      } else {
        if (window.innerWidth < 1200) state.isOpen = payload;
      }
    },
  },
});

export const { handleSidebarLayout, handleOpenSideBar } = sidebarSlice.actions;

export const useLayoutSlice = () =>
  useSelector((state: RootState) => state.sidebar);
export const selectIsOpen = (state: RootState) => state.sidebar.isOpen;

export default sidebarSlice.reducer;
